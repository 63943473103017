import { graphql, PageProps } from "gatsby"
import React, { FunctionComponent } from "react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { MDXProvider } from "@mdx-js/react"
import ArticleTemplate from "../components/blog/Article"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import SocialShare, { SocialShareType } from "../components/blog/SocialShare"
import SimilarArticle from "../components/blog/SimilarArticle"
import Container from "../components/Container"
import Img from 'gatsby-image'
import YouTube from '../components/mdx/YouTube'
import useSiteMetadata from "../hooks/useSiteMetadata"
import {
  Article as ArticleQueryResult,
  ArticleAuthor, ArticleSummary as ArticleSummaryResult,
  ArticleSummary as ArticleSummaryQueryResult
} from "../types/QueryResults"
import kebabCase from "lodash/kebabCase"
import Pill from "../components/Pill"
import { Link } from "gatsby"
import ArticleSummary from "../components/blog/ArticleSummary"

export const query = graphql`
  query($id: String) {
    authorYaml(id: {eq: $id}) {
      id
      bio
      linkedin
      photo {
        childImageSharp {
            fluid(maxWidth: 200) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
        }
      }
    }
    allMdx(
      filter: {frontmatter: {author: {eq: $id}}}
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          frontmatter {
            author
            date(formatString: "YYYY-MM-DD")
            heroImage {
              childImageSharp {
                fluid(maxWidth: 1600, maxHeight: 900) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            title
            slug
            summary
          }
          id
        }
      }
    }
  }
`

interface QueryResult {
  authorYaml: ArticleAuthor
  allMdx: {
    edges: { node: ArticleSummaryResult }[]
  }
}

type AuthorProps = PageProps<QueryResult>

/**
 * Make the author page component
 */
const Author: FunctionComponent<AuthorProps> = ({ data }) => {

  const authorData = data.authorYaml
  const articlePath = `/blog/authors/${kebabCase(data.authorYaml.id)}`
  const photo = authorData.photo?.childImageSharp.fluid
  const articles = data.allMdx.edges.map(({ node }) => {
    const fm = node.frontmatter
    return {
      articleUrl: `/blog/${fm.slug}`,
      authorName: fm.author,
      heroImage: fm.heroImage?.childImageSharp,
      publicationDate: new Date(fm.date),
      summary: fm.summary,
      title: fm.title
    }
  })

  return (
    <Layout disableIntercom>
      <Seo
        description={authorData.bio}
        pathname={articlePath}
        title={authorData.id}
        // image={articleFrontmatter.ogImage.childImageSharp.fixed.src}
      />


      <header>
        <Pill bgClass="bg-green-100" variant="bottom-right">
          <Container>
            <div className="br-prose py-12 xxl:py-24">
              <div className="br-col-gap md:grid md:grid-cols-12">

                  <div className="md:col-span-2 md:col-start-2 flex justify-center mb-8 md:justify-start">
                    {!!photo &&
                      <Img fluid={photo} className="rounded-full w-full" style={{ alignSelf: "center" }} alt="avatar"/>
                    }
                  </div>
                <div className="md:col-span-8 text-center md:text-left">
                  <h1 className="mb-8">{authorData.id}</h1>
                  <p >
                    {authorData.bio}
                  </p>
                </div>
              </div>
            </div>
          </Container>
        </Pill>
      </header>


      <h3 className="text-center pt-20">Articles by {authorData.id}</h3>

      <section className="mb-12 py-12 xxl:mb-12 xxl:py-24">
        <Container>

          <div className="br-col-gap md:grid md:grid-cols-12">
            <div className="md:col-span-10 md:col-start-2 xxl:col-span-8 xxl:col-start-3">
              <div className="br-col-gap grid grid-cols-2 row-gap-16">

                {articles &&
                articles.map((article, index) => (
                  <div key={index} className="col-span-2 xl:col-span-1">
                    <ArticleSummary
                      articleUrl={article.articleUrl}
                      authorName={article.authorName}
                      heroImage={article.heroImage}
                      publicationDate={article.publicationDate}
                      summary={article.summary}
                      title={article.title}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </Container>
      </section>


      <div className="br-prose text-1 text-center xxl:text-2 mb-32"><Link to="/blog">See all articles</Link></div>

      <section className="bg-blue-500 rounded-lg text-white" id="footerCta">
        <Container>
          <div className="py-8">
            <div className="flex flex-row flex-wrap items-center justify-center text-center">
              <div className="p-4" style={{ minWidth: "25ch" }}>
                Speak to us to level up your partner discovery.
              </div>

              <div className="p-4">
                <a
                  className="
                    bg-white border border-white cursor-pointer duration-500 font-bold block px-8 py-3 rounded-full shadow-md text-blue
                    hover:bg-blue-100 hover:shadow-lg hover:text-blue-800"
                  href="/signup"
                >
                  Book a demo
                </a>
              </div>
            </div>
          </div>
        </Container>
      </section>

    </Layout>
  )
}

export default Author
